import { SerializedStyles } from '@emotion/react';
import Link from 'next/link';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IconGNBCategory } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { useFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import {
  HorizontalScrollContainer,
  HorizontalScrollContainerController,
} from '@/components/common/HorizontalScrollContainer';
import { usePageContext } from '@/components/common/PageContext';
import { RIDITheme } from '@/components/styles/themes';
import { currentNavigationRouteSelector } from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { useNavigationScrollIntoView } from '@/hooks/useNavigationScrollIntoView';
import { useNavigationScrollPosition } from '@/hooks/useNavigationScrollPosition';
import { useOnScrollRightEnd } from '@/hooks/useOnScrollRightEnd';
import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';
import { isNewNavigation } from '@/utils/featureFlag/genreHomeNavigation';

import { ChannelLinks } from './ChannelLinks';
import * as styles from './GenreHomeTab.styles';

const GenreHomeTabItemsAB = ({ items, itemCss, selectedId, target, className }: GenreHomeTabItemsProps) => {
  const pageContext = usePageContext();
  const [hideGradient, setHideGradient] = useState(true);
  const scrollableRef = useOnScrollRightEnd<HTMLUListElement>({ onScrollRightEnd: setHideGradient });
  const isReadyToBeVisible = useNavigationScrollPosition(scrollableRef);
  const [isManuallySelected, setIsManuallySelected] = useState(false);
  const getRef = useNavigationScrollIntoView(selectedId, items, isManuallySelected);

  return (
    <div css={styles.wrapperStyle2}>
      <ul className={className} css={[styles.defaultItemsStyle]} ref={scrollableRef} aria-hidden={!isReadyToBeVisible}>
        {items.map((item, index) => {
          const eventParams = { ...pageContext.params, path: item.path, title: item.title, index };
          return (
            <TrackViewEvent
              key={`navigation-${item.id}`}
              screenName={pageContext.screenName}
              target={target}
              params={eventParams}>
              <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
                <li css={styles.defaultItemContainer2Style} ref={getRef(item.id)}>
                  <Link
                    aria-selected={item.id === selectedId}
                    css={itemCss}
                    href={item.path}
                    key={item.id}
                    onClick={() => setIsManuallySelected(true)}>
                    {item.title}
                  </Link>
                </li>
              </TrackClickEvent>
            </TrackViewEvent>
          );
        })}
      </ul>
      <div aria-hidden={hideGradient} css={styles.buttonsBg2Styles} />
    </div>
  );
};

type GenreHomeTabItems2Props = GenreHomeTabItemsProps & {
  onScrollRightEnd: (isRightEnd: boolean) => void;
};

const GenreHomeTabItemsB = ({
  items,
  itemCss,
  selectedId,
  target,
  className,
  onScrollRightEnd,
}: GenreHomeTabItems2Props) => {
  const pageContext = usePageContext();
  const scrollableRef = useOnScrollRightEnd<HTMLUListElement>({ onScrollRightEnd });

  return (
    <ul className={className} css={styles.defaultItemsStyle} ref={scrollableRef}>
      {items.map((item, index) => {
        const eventParams = { ...pageContext.params, path: item.path, title: item.title, index };
        return (
          <TrackViewEvent
            key={`navigation-${item.id}`}
            screenName={pageContext.screenName}
            target={target}
            params={eventParams}>
            <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
              <li css={styles.defaultItemContainer2Style}>
                <Link aria-selected={item.id === selectedId} css={itemCss} href={item.path} key={item.id}>
                  {item.title}
                </Link>
              </li>
            </TrackClickEvent>
          </TrackViewEvent>
        );
      })}
    </ul>
  );
};

interface GenreHomeTabItemsProps {
  items: Navigation[];
  itemCss?: (theme: RIDITheme) => SerializedStyles;
  itemActiveCss?: (theme: RIDITheme) => SerializedStyles;
  selectedId: number;
  target: string;
  className?: string;
  children?: ReactNode;
  isInExperimentPage: boolean;
}

const GenreHomeTabItems = ({
  items,
  itemCss,
  itemActiveCss,
  selectedId,
  target,
  className,
  children,
  isInExperimentPage,
}: GenreHomeTabItemsProps): ReactJSX.Element => {
  const pageContext = usePageContext();
  const containerControllerRef = useRef<HorizontalScrollContainerController>(null);
  const childrenRefs = useRef<Record<number, HTMLElement>>({});
  const setChildRef = (itemId: number) => (element: HTMLElement | null) => {
    if (!element) {
      delete childrenRefs.current[itemId];
      return;
    }

    childrenRefs.current[itemId] = element;
  };

  useEffect(() => {
    if (!containerControllerRef.current) {
      return;
    }

    const activeCategoryElement = childrenRefs.current[selectedId ?? 0];
    if (!activeCategoryElement) {
      return;
    }

    containerControllerRef.current.focus(activeCategoryElement);
  }, [selectedId]);

  const navigationFeatureFlag = isNewNavigation(
    useFeatureFlagValueByKey('web-genre-home-navigation-20240903'),
    isInExperimentPage,
  );

  return (
    <HorizontalScrollContainer contentCss={styles.scrollWrapperStyle} ref={containerControllerRef}>
      <ul className={className} css={styles.defaultItemsStyle}>
        {items.map((item, index) => {
          const eventParams = { ...pageContext.params, path: item.path, title: item.title, index };
          return (
            <TrackViewEvent
              key={`navigation-${item.id}`}
              screenName={pageContext.screenName}
              target={target}
              params={eventParams}
              ref={setChildRef(item.id)}>
              {navigationFeatureFlag ? (
                <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
                  <li css={styles.defaultItemContainer2Style}>
                    <Link aria-selected={item.id === selectedId} css={itemCss} href={item.path} key={item.id}>
                      {item.title}
                    </Link>
                  </li>
                </TrackClickEvent>
              ) : (
                <li css={styles.defaultItemContainerStyle}>
                  <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
                    <Link legacyBehavior href={item.path} key={item.id} passHref>
                      <a href={item.path} css={[itemCss, item.id === selectedId && itemActiveCss]}>
                        {item.title}
                      </a>
                    </Link>
                  </TrackClickEvent>
                </li>
              )}
            </TrackViewEvent>
          );
        })}
        {children}
      </ul>
    </HorizontalScrollContainer>
  );
};

interface GenreHomeTabProps {
  className?: string;
  children?: ReactNode;
  isInExperimentPage: boolean;
}

export const GenreHomeTab = ({ className, children, isInExperimentPage }: GenreHomeTabProps): ReactJSX.Element => {
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);
  const [hideBgGradient, setHideBgGradient] = useState(false);

  // prettier-ignore
  const [/* selectedRootNavigation */, selectedGlobalNavigation, selectedTabItem, selectedSubTabItem] =
    currentNavigationRoute ?? [];

  const tabItems = selectedGlobalNavigation?.children;
  const subTabItems = selectedTabItem?.children;

  const pageContext = usePageContext();

  const featureFlag = useFeatureFlagValueByKey('web-genre-home-navigation-20240903');
  const navigationFeatureFlag = isNewNavigation(featureFlag, isInExperimentPage);
  const navigationToggleFeatureFlag = featureFlag === 'b';
  const hasMultipleTabItems = tabItems?.length > 1;
  const doesTabItemsExist = tabItems && selectedTabItem;
  const doesSubTabItemsExist = subTabItems && selectedSubTabItem;

  return (
    <div css={styles.wrapperStyle}>
      <div css={navigationFeatureFlag ? styles.tabWrapperStyleAB : styles.tabWrapperStyle} className={className}>
        {!navigationFeatureFlag && doesTabItemsExist && (
          <GenreHomeTabItems
            css={styles.tabStyle}
            items={tabItems}
            itemCss={styles.tabItemStyle}
            itemActiveCss={styles.tabItemActiveStyle}
            selectedId={selectedTabItem.id}
            target="tab"
            isInExperimentPage={isInExperimentPage}>
            <li css={styles.allCategoriesStyle} key="all-categories">
              <TrackClickEvent screenName={pageContext.screenName} target="all_categories" params={pageContext.params}>
                <a href="/category/list" css={styles.allCategoriesLinkStyle}>
                  <IconGNBCategory css={styles.allCategoriesIconStyle} />
                  <span css={styles.allCategoriesTextStyle}>전체 카테고리</span>
                </a>
              </TrackClickEvent>
            </li>
          </GenreHomeTabItems>
        )}

        {!navigationFeatureFlag && <hr css={styles.tabDividerStyle} />}

        {navigationFeatureFlag ? (
          <>
            <div css={styles.subTabContainerStyle}>
              {hasMultipleTabItems
                ? // 웹소설
                  doesTabItemsExist &&
                  (navigationToggleFeatureFlag ? (
                    // 로맨스/로판/판타지/BL - B안
                    <GenreHomeTabItemsB
                      css={styles.subTab3Style}
                      items={tabItems}
                      itemCss={styles.subTabItem2Style}
                      selectedId={selectedTabItem.id}
                      target="tab"
                      onScrollRightEnd={setHideBgGradient}
                      isInExperimentPage={isInExperimentPage}
                    />
                  ) : (
                    // 로맨스/로판/판타지/BL - A안
                    <GenreHomeTabItems
                      css={styles.subTab4Style}
                      items={tabItems}
                      itemCss={styles.subTabItem3Style}
                      selectedId={selectedTabItem.id}
                      target="tab"
                      isInExperimentPage={isInExperimentPage}
                    />
                  ))
                : // 웹소설 제외 (웹툰, 만화, 도서)
                  doesSubTabItemsExist && (
                    <GenreHomeTabItemsAB
                      css={[styles.subTab2Style, styles.subTab6Style]}
                      items={subTabItems}
                      itemCss={styles.subTabItem2Style}
                      selectedId={selectedSubTabItem.id}
                      target="subtab"
                      isInExperimentPage={isInExperimentPage}
                    />
                  )}
              {navigationToggleFeatureFlag && hasMultipleTabItems && subTabItems.length > 0 && (
                // 웹소설 - 웹소설/e북 - B안
                <ChannelLinks channels={subTabItems} hideBgGradient={hideBgGradient} />
              )}
            </div>
            {!navigationToggleFeatureFlag && hasMultipleTabItems && doesSubTabItemsExist && (
              // 웹소설 - 웹소설/e북 - A안
              <GenreHomeTabItems
                css={styles.subTab5Style}
                items={subTabItems}
                itemCss={styles.subTabItem2Style}
                selectedId={selectedSubTabItem.id}
                target="subtab"
                isInExperimentPage={isInExperimentPage}
              />
            )}
          </>
        ) : (
          doesSubTabItemsExist && (
            <GenreHomeTabItems
              css={styles.subTabStyle}
              items={subTabItems}
              itemCss={styles.subTabItemStyle}
              itemActiveCss={styles.subTabItemActiveStyle}
              selectedId={selectedSubTabItem.id}
              target="subtab"
              isInExperimentPage={isInExperimentPage}
            />
          )
        )}
        {children}
      </div>
    </div>
  );
};
