import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import * as styles from './GenreHomeTab.styles';

type Props = {
  channels: {
    id: number;
    title: string;
    path: string;
  }[];
  hideBgGradient: boolean;
};

export const ChannelLinks = ({ channels, hideBgGradient }: Props) => {
  const { asPath } = useRouter();
  // asPath에 해당하는 채널이 없을 경우 (-1) 첫번째 채널을 선택하도록 Math.max를 사용
  const initialSelectedIndex = Math.max(
    channels.findIndex(channel => channel.path === asPath),
    0,
  );

  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

  useEffect(() => {
    setSelectedIndex(initialSelectedIndex);
  }, [initialSelectedIndex]);

  return (
    <div css={styles.buttonsStyle}>
      <div aria-hidden={hideBgGradient} css={styles.buttonsBgStyles} />
      {channels.map((channel, i) => (
        <Link
          key={channel.id}
          href={channel.path}
          aria-selected={selectedIndex === i}
          css={styles.buttonStyle}
          onClick={() => setSelectedIndex(i)}>
          {channel.title}
        </Link>
      ))}
      <div css={styles.sliderStyle} style={{ transform: `translateX(${selectedIndex * 100}%)` }} />
    </div>
  );
};
